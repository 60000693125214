<template>
  <div class="pb-2 text-center text-3xl">
    Zone list
  </div>
  <div class="mx-auto mb-2 max-w-7xl rounded-md border-2 border-brand-800 bg-darkbg-500">
    <div v-for="zone in zones" :key="zone.id" class="flex items-center gap-2 p-1 hover:bg-darkbg-400">
      <div>
        <cgn-link :to="`/editZone/${zone.id}`">
          <i-heroicons-solid:pencil />
        </cgn-link>
      </div>
      <div>
        {{ zone.name }}
      </div>
      <div v-if="false">
        {{ zone.usage }}
      </div>
    </div>
  </div>
  <cgn-button color-brand fullwidth class="mx-auto max-w-7xl" @click="showCreate = true">
    <i-heroicons-solid:plus />
  </cgn-button>
  <div>
    Total usage: {{ totalQueries }}
  </div>
  <form v-if="showCreate" class="mx-auto mt-2 max-w-7xl rounded-md border-2 border-brand-800 bg-darkbg-500 p-2 pt-1" @submit.prevent="addZone()">
    <cgn-form-input-text v-model="newZoneName" label="Name" required />
    <cgn-button color-brand fullwidth>
      Create
    </cgn-button>
  </form>
</template>

<script setup lang="ts">
import { DnsZone } from '~/models/Dns/Zone'

const showCreate = ref(false)
const newZoneName = ref('')
const zones = ref<DnsZone[]>([])
const totalQueries = computed(() => {
  return zones.value.reduce((accumulator, zone) => {
    return accumulator + zone.usage
  }, 0)
})

const getZones = async () => {
  zones.value = (await new DnsZone().find_many({})).mapped
  const res = await (new DnsZone()).listZones()
  console.log({ res })
  // zones.value.forEach(e => e.getUsage())
}

const addZone = async () => {
  await new DnsZone().addZone(newZoneName.value)
  getZones()
  newZoneName.value = ''
  showCreate.value = false
}

onMounted(() => {
  getZones()
})
</script>
