import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://dns.${config.devbase}`
config.prodURL = 'https://cms.dns.jm1.au'
config.siteURL = 'https://dns.jm1.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

// config.sentryDsn = 'https://68125b41223a49bfaef13a3a42464a9f@glitchtip.logger.jm1.au/9'

export { config }
