import type { DnsRecord } from '~/models/Dns/Record'
import { CognitoBase } from '~cognito/models/Cognito/Base'
import { $axios } from '~cognito/plugins/axios'

class DnsZone extends CognitoBase {
  name: string
  records: DnsRecord[]

  baseurl(): string {
    return '/api/v1/dns/zone'
  }

  constructor(source?: Partial<DnsZone>) {
    super()
    this.name = ''
    this.records = []
    Object.assign(this, source)
  }

  async getZone(): Promise<DnsZone> {
    const res = await $axios.get(`/api/v1/dns/zone/getZone?zone=${this.id}`)
    return new DnsZone(res.data)
  }

  async listZones(): Promise<DnsZone[]> {
    const res = await $axios.get(`/api/v1/dns/zone/listZones`)

    return res.data
  }

  async addZone(name: string) {
    await $axios.post('/api/v1/dns/zone/addZone', {
      name,
    })
  }
}

export { DnsZone }
