<template>
  <div>
    <zone-list />
  </div>
</template>

<route lang="yaml">
meta:
  requiresAuth: true
</route>
